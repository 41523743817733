import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'

const Div = styled.div`
  .hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 61.8vh;
    max-height: 600px;

    text-align: center;
  }

  .heroImage {
    position: relative;
    height: 600px;
    width: 600px;
    max-height: 450px;
  }

  @media (max-width: 600px) {
    .heroImage {
      width: 100%;
    }
  }
`

const BlogPostTemplate = (props) => {
  const post = props?.data?.contentfulEvents

  return (
    <Layout location={props.location}>
      <Div
        style={
          {
            // background: '#f2f1e2',
            // borderRadius: 5,
            // boxShadow: '15px 10px 40px -2px rgba(0,0,0,.8)',
          }
        }
      >
        <Helmet title={post.title} />
        <div className="wrapper">
          <h1 className="section-headline">{post.title}</h1>
          <p
            style={{
              display: 'block',
            }}
          >
            {post.publishDate}
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
        </div>
      </Div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query EventQuery($slug: String!) {
    contentfulEvents(title: { eq: $slug }) {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
